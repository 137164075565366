/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import copy from "copy-to-clipboard";
import { Form, Formik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useParams } from "react-router-dom";

import createQuestionResponse from "../../graphql/mutation/createQuestionResponse";
import Input from "../layout/Input/Input";
import Loading from "../layout/Loading/Loading";
import Select from "../layout/Select";
import QuestionResponse from "./QuestionResponse";

export const questionQuery = gql`
  query question($link: String!, $ip: String!) {
    question(link: $link) {
      id
      title
      createdAt
      response {
        id
        title
        likeCount
        desLikeCount
        youVoit(ip: $ip)
      }
    }
  }
`;

const Question = ({ ip }) => {
  const { link } = useParams();
  const [isCopy, setIsCopy] = useState(false);

  const countPostsOptions = [
    { id: 0, title: "Виберіть..." },
    { id: 10, title: "Останні 10" },
    { id: 20, title: "Останні 20" },
    { id: 30, title: "Останні 30" }
  ];
  const [countPosts, setCountPosts] = useState();
  const [newResponse, setNewResponse] = useState();
  const [createQuestionResponseMutation] = useMutation(createQuestionResponse);
  const { loading, data, refetch } = useQuery(questionQuery, {
    variables: {
      link,
      ip
    }
  });

  if (loading) return <Loading />;
  const { question } = data;
  const crateResponse = async () => {
    await createQuestionResponseMutation({
      variables: {
        input: {
          questionId: question.id,
          title: newResponse
        }
      }
    });
    setNewResponse("");
    await refetch();
  };
  const linkHref = window.location.href;
  const copyLink = async () => {
    copy(linkHref);
    setIsCopy(true);
    return null;
  };
  return (
    <>
      <header className="page-header">
        <div className="container">
          <div className="page-header-inner">
            <h1 className="page-title">{question.title}</h1>
            <span className="page-header-date">
              {moment(question.createAt).format("DD.mm.YYYY")}
            </span>
            <div className="page-header-postPerPage">
              <Select
                key="countPosts"
                value={countPosts}
                options={countPostsOptions}
                id="countPosts"
                onChange={async (val) => {
                  await setCountPosts(val);
                }}
              />
            </div>
          </div>
        </div>
      </header>
      <div className="page-content">
        <Formik>
          <Form>
            <div className="container">
              <div className="col-lg-8 mb-3 mb-lg-0">
                <div className="form-field form-field-url">
                  <Input
                    disabled
                    label="Ваше унікальне посилання:"
                    type="text"
                    className="form-control"
                    id="url"
                    name="url"
                    value={linkHref}
                  />
                  <span onClick={copyLink} className="btn btn-primary">
                    {isCopy ? "Скопійовано" : "Скопіювати"}
                  </span>
                </div>
              </div>
              <div className="poll-items">
                {question.response.map(
                  (
                    { id: responseId, youVoit, title, likeCount, desLikeCount },
                    index
                  ) => (
                    <QuestionResponse
                      title={title}
                      likeCount={likeCount}
                      desLikeCount={desLikeCount}
                      number={`${index + 1}.`}
                      responseId={responseId}
                      youVoit={youVoit}
                      ip={ip}
                      refetch={refetch}
                    />
                  )
                )}
              </div>
              <div className="poll-formShort">
                <div className="form-field">
                  <textarea
                    value={newResponse}
                    onChange={(val) => {
                      setNewResponse(val.target.value);
                    }}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Текст не більше 250 символів"
                  ></textarea>
                </div>
                <div className="form-field mb-0">
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-xl-10">
                      <p className="text-lg-end mb-3 mb-lg-0">
                        Дякую ваше повідовлення надіслане! Наступне повідомлення
                        можна буде надіслати через 2 годин.
                      </p>
                    </div>
                    <div className="col-lg-3 col-xl-2">
                      <button
                        onClick={crateResponse}
                        className="btn w-100 btn-primary"
                      >
                        Опублікувати
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

Question.propTypes = {
  ip: PropTypes.string
};
export default Question;
