import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import createQuestion from "../../graphql/mutation/createQuestion";
import { getQuestionUrl } from "../../routes";
import DataPiker from "../layout/DatetimePicker";
import Input from "../layout/Input/Input";
import Item from "../layout/Item";
import Loading from "../layout/Loading/Loading";
import Select from "../layout/Select";

export const questionsQuery = gql`
  query questions {
    questions {
      title
      link {
        link
      }
    }
  }
`;
const AddQuestion = () => {
  const countPostsOptions = [
    { id: 0, title: "Виберіть..." },
    { id: 10, title: "Останні 10" },
    { id: 20, title: "Останні 20" },
    { id: 30, title: "Останні 30" }
  ];
  const history = useHistory();
  const [values, setValues] = useState({
    countPosts: countPostsOptions[0].id,
    datatime: new Date()
  });
  const { loading, data } = useQuery(questionsQuery);
  const [createQuestionMutation] = useMutation(createQuestion);
  if (loading) return <Loading />;

  const onSave = async (val) => {
    const createQuestionData = await createQuestionMutation({
      variables: {
        input: {
          title: val.title,
          dateTo: values.datatime,
          countDisplay: parseInt(values.countPosts, 10),
          isPrivat: val.isPrivate === "true"
        }
      }
    });
    const url = queryString.stringifyUrl({
      url: getQuestionUrl(createQuestionData.data.createQuestion.link),
      query: {}
    });

    history.push(url);
  };
  return (
    <>
      <header className="page-header">
        <div className="container">
          <h1 className="page-title">Додати тему</h1>
        </div>
      </header>
      <div className="page-content">
        <div className="container">
          <div className="poll-form">
            <Formik
              initialValues={{ link: values.link, isPrivate: "true" }}
              onSubmit={onSave}
            >
              {() => (
                <Form>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-field">
                        <Input
                          type="text"
                          label="Тема опитування"
                          id="inputTitle"
                          required=""
                          name="title"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mb-3">
                      <div className="form-field">
                        <label className="form-label">Година та дата до:</label>
                        <DataPiker
                          value={values.datatime}
                          onChange={async (val) => {
                            await setValues({
                              ...values,
                              datatime: val
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mb-3">
                      <div className="form-field">
                        <Select
                          key="countPosts"
                          value={values.countPosts}
                          options={countPostsOptions}
                          id="countPosts"
                          label="Відображати:"
                          onChange={async (val) => {
                            await setValues({
                              ...values,
                              countPosts: val
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mb-3">
                      <div className="form-field">
                        <label className="form-label">Публічне</label>
                        <div className="form-check form-check-inline form-poll-radio">
                          <Input
                            type="radio"
                            name="isPrivate"
                            id="inlineRadio1"
                            value="true"
                            label="Так"
                          />
                        </div>
                        <div className="form-check form-check-inline form-poll-radio">
                          <Input
                            type="radio"
                            name="isPrivate"
                            id="inlineRadio2"
                            value="false"
                            label="Ні"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 offset-lg-10 mb-3 mb-lg-0">
                      <button className="btn w-100 btn-primary btn-submit">
                        Зберегти
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <h4>Останні додані теми опитування</h4>
          <div className="poll-items mb-0">
            {data.questions.map(({ title, link }, index) => (
              <Item
                title={title}
                number={`${index + 1}.`}
                link={getQuestionUrl(link.link)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

AddQuestion.propTypes = {
  filter: PropTypes.object
};
export default AddQuestion;
