/* eslint-disable react/prop-types */
import "../../../css/bootstrap.min.css";

import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { UserContext } from "../../../utility/context/User";
import Footer from "../Footer";
import Header from "../Header";

const Layout = ({ children }) => {
  const { user, logout } = useContext(UserContext);
  const { pathname } = useLocation();
  return (
    <div className="wrapper">
      <Header {...{ user, pathname, logout }} />
      <main className="main-content">
        <div className="container">{children}</div>
      </main>
      <Footer />
      <div className="modal-mobile">
        <div className="modal-mobile-bg"></div>
      </div>
    </div>
  );
};
export default Layout;
