import { Field } from "formik";
import PropTypes from "prop-types";

import { classNames } from "../../../utility";

const Input = (props) => {
  const { addonAfter, label, id, type, error } = props;
  if (type === "checkbox")
    return (
      <>
        <Field className={classNames("form-check-input")} {...{ ...props }} />
        {label ? (
          <label htmlFor={id} className="form-check-label">
            {label}
          </label>
        ) : null}
      </>
    );
  if (type === "radio")
    return (
      <>
        <Field className={classNames("form-check-input")} {...{ ...props }} />
        {label ? (
          <label htmlFor={id} className="form-check-label">
            {label}
          </label>
        ) : null}
      </>
    );
  return (
    <>
      {label ? (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      ) : null}
      <Field
        className={classNames(
          "form-control",
          error ? "border border-danger" : ""
        )}
        {...{ ...props }}
      />
      {addonAfter}
    </>
  );
};
Input.propTypes = {
  error: PropTypes.string,
  addonAfter: PropTypes.object,
  addonbefore: PropTypes.object,
  errorclassname: PropTypes.shape({
    error: PropTypes.string,
    notError: PropTypes.string
  }),
  label: PropTypes.any,
  id: PropTypes.string,
  type: PropTypes.string
};
Input.defaultProps = {
  errorclassname: {}
};
export default Input;
