import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import createQuestionResponseVoit from "../../../graphql/mutation/createQuestionResponseVoit";

const QuestionResponse = ({
  title,
  number,
  likeCount,
  desLikeCount,
  responseId,
  youVoit,
  ip,
  refetch
}) => {
  const [createQuestionResponseVoitMutation] = useMutation(
    createQuestionResponseVoit
  );
  const createQuestionResponseVoitFun = async (x) => {
    if (youVoit) return;
    await createQuestionResponseVoitMutation({
      variables: {
        input: {
          ip,
          isLike: x,
          questionResponseId: responseId
        }
      }
    });
    await refetch();
  };

  return (
    <div className="poll-item">
      <div className="poll-item_action">
        <button
          className={`plus ${youVoit === "like" ? "active" : ""}`}
          onClick={() => createQuestionResponseVoitFun(true)}
        >
          <span>{likeCount}</span>голосів
        </button>
        <button
          className={`minus ${youVoit === "desLike" ? "active" : ""}`}
          onClick={() => createQuestionResponseVoitFun(false)}
        >
          <span>{desLikeCount}</span>голосів
        </button>
      </div>
      <div className="poll-item_info">
        <span className="poll-item_number">{number}</span>
        <p className="poll-item_content">{title}</p>
      </div>
    </div>
  );
};

QuestionResponse.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  likeCount: PropTypes.number,
  desLikeCount: PropTypes.number,
  responseId: PropTypes.string,
  youVoit: PropTypes.string,
  ip: PropTypes.string,
  refetch: PropTypes.func
};
QuestionResponse.defaultProps = {
  refetch: () => {}
};
export default QuestionResponse;
