/* eslint-disable react/prop-types */
import React, { useState } from "react";

import FallbackSpinner from "../../components/layout/Loading/Loading";
import { setJWT } from "../localStorage";

export const UserContext = React.createContext({});
export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({ df: "tt" });

  if (user === null) return <FallbackSpinner />;

  const login = (jwt) => {
    setJWT(jwt);
  };

  const logout = () => {
    setJWT(null);
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
