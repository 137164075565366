import { useEffect, useState } from "react";

import SinglePost from "./Question";

const publicIp = require("react-public-ip");

const QuestionResponse = () => {
  const [ip, setIp] = useState();
  useEffect(() => {
    const t = async () => {
      const i = (await publicIp.v4()) || "";
      setIp(i || "");
    };
    t();
  });
  if (!ip) return null;
  return <SinglePost ip={ip} />;
};
export default QuestionResponse;
